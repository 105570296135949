import type { GetServerSideProps, NextPage } from "next";
import {
  ContentModule,
  getGlobals,
  getGraphqlSdk,
  GlobalProps,
  GraphQLResponseError
} from "@src/lib/services/server/contentful";
import type {} from "@src/lib/types";
import type {
  ServiceQuery,
  ServiceContentModulesQuery,
  ServiceQueryVariables,
  ProductNavigationQuery,
  ProductFooterQuery,
  ServiceContentModules2Query
} from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { logError } from "@src/lib/services/server/logger";
import Error from "next/error";
import { filterNull } from "@src/lib/utils";
import "setimmediate";
import {
  ProductLayout,
  ProductContentModules,
  ProductHeroService
} from "@src/components";
import { Pillar } from "@src/lib/services/server/contentful";
import { setCacheHeaders } from "@src/lib/utils/pageCache";
import { PageCacheGroups } from "@src/lib/types/fastly";

export type ServiceParams = {
  slug: string[];
};

export type ServiceProps = GlobalProps & {
  entry?: ServiceQuery;
  contentModules?: ServiceContentModulesQuery;
  contentModules2?: ServiceContentModules2Query;
};

export const Service: NextPage<ServiceProps> = ({
  entry,
  contentModules,
  contentModules2,
  ...layoutProps
}: ServiceProps) => {
  if (!entry) {
    return <Error statusCode={500} />;
  }

  const entryItem = entry.serviceCollection
    ? entry.serviceCollection.items[0]
    : null;

  const contentModulesItem =
    contentModules && contentModules.serviceCollection
      ? contentModules.serviceCollection.items[0]
      : null;

  const contentModules2Item =
    contentModules2 && contentModules2.serviceCollection
      ? contentModules2.serviceCollection.items[0]
      : null;

  if (!entryItem) {
    return null;
  }

  const contentModulesItems = filterNull(
    contentModulesItem?.contentModulesCollection?.items
  );

  const contentModules2Items = filterNull(
    contentModules2Item?.contentModulesCollection?.items
  );

  type ContentModuleItem = (typeof contentModulesItems)[0] &
    (typeof contentModules2Items)[0];

  const combinedContentModules = contentModulesItems.map<ContentModuleItem>(
    (contentModule, count) => {
      if ("sys" in contentModule) {
        return contentModule;
      } else {
        return {
          ...contentModules2Items[count]
        } as ContentModuleItem;
      }
    }
  );

  const pillarCollection = filterNull(
    entryItem.linkedFrom?.pillarCollection?.items
  );

  console.log("entry is: ", entryItem);

  return (
    <ProductLayout entry={entryItem} navigationStyle="white" {...layoutProps}>
      <section>
        <ProductHeroService
          heading={entryItem.title}
          text={entryItem.description}
          image={entryItem.leadImage}
          eyebrow={
            pillarCollection.length > 0 ? pillarCollection[0].title : null
          }
          icon={pillarCollection.length > 0 ? pillarCollection[0].icon : null}
          heroStyle={entryItem.heroStyle?.toLowerCase().split(" ").join("")}
          ctaTitle={entryItem.ctaTitle}
          ctaUrl={entryItem.ctaUrl}
          ctaStyle={entryItem.ctaStyle?.toLowerCase().split(" ").join("")}
        />
        <div>
          <ProductContentModules
            contentModules={combinedContentModules as ContentModule[]}
          />
        </div>
      </section>
    </ProductLayout>
  );
};

export const getServerSideProps: GetServerSideProps<
  ServiceProps,
  ServiceParams
> = async context => {
  const { params, preview = false } = context;
  const props: ServiceProps = {};

  if (!params) {
    return { notFound: true };
  }

  const { slug } = params;

  const urlPath = `${slug.join("/")}`;
  const query: ServiceQueryVariables = { urlPath, preview };
  const graphqlSdk = getGraphqlSdk(preview);
  const serviceQuery = graphqlSdk.service(query);
  const serviceContentModulesQuery = graphqlSdk.serviceContentModules(query);
  const serviceContentModules2Query = graphqlSdk.serviceContentModules2(query);
  const globalsQuery = getGlobals(preview);

  Object.assign(props, await globalsQuery);

  setCacheHeaders(PageCacheGroups.SERVICES, context);

  try {
    props.entry = await serviceQuery;
  } catch (e) {
    const error = e as GraphQLResponseError<ServiceQuery>;
    if (error?.response?.data) {
      props.entry = error.response.data;
    } else {
      logError(error);
      return { props };
    }
  }

  try {
    props.contentModules = await serviceContentModulesQuery;
  } catch (error) {
    logError(error);
    props.contentModules = undefined;
  }

  try {
    props.contentModules2 = await serviceContentModules2Query;
  } catch (error) {
    logError(error);
    props.contentModules2 = undefined;
  }

  const entryItem = props.entry.serviceCollection
    ? props.entry.serviceCollection.items[0]
    : null;

  if (!entryItem) {
    return { notFound: true };
  }

  return { props };
};

export default Service;
